import {
  IEmailVerificationToken,
  IReadTransactions,
  IReadPayin,
  ICreditCardCreate,
  IRechargeWalletCreate,
  ICreditCardCreateAndPayin,
  IMangopayCreditCardRegistration,
  IMangopayCardData,
  IMangopayUpdateCreditCardRegistration,
  IMangopayPostCardData,
  IUpdateRegistration,
  ISlimpayOrderCreate,
  ISlimpayOrder,
  IGocardlessBillingRequestCreate,
  ITinkReport,
} from "../interfaces/index";
import { api } from "@/api";
import router from "@/router";
import { AxiosError } from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetUserProfile,
  commitSetCreditCards,
  commitSetWallet,
  commitAppendTransactions,
} from "./mutations";
import { AppNotification, State } from "./state";
import { appUrl, environment } from "@/env"

type Context = ActionContext<State, State>;
export const actions = {
  async getUserProfile(context: Context) {
    try {
      const response = await api.getMe(context.state.keycloak.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getUserProfileOrCreate(context: Context) {
    try {
      const response = await api.getMeOrCreate(context.state.keycloak.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      return
    }
  },
  async updateUserProfile(context: Context, payload) {
    try {
      const response = await api.updateMe(
        context.state.keycloak.token,
        payload
      );
      commitSetUserProfile(context, response.data);
      commitAddNotification(context, {
        content: "Vos informations de profil ont été mises à jour",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async logOut(context: Context) {
    context.state.keycloak.logout({ redirectUri: appUrl });
  },
  async checkApiError(context: Context, payload: AxiosError) {
    if (payload.response && payload.response.status === 401) {
      await dispatchLogOut(context);
    }
    commitAddNotification(context, {
      content: "Un problème est survenu.",
      color: "error",
    });
    throw payload;
  },
  async removeNotification(
    context: Context,
    payload: { notification: AppNotification; timeout: number }
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async resetPassword(context: Context, password: string) {
    try {
      const response = (
        await Promise.all([
          api.resetPassword(context.state.keycloak.token, password),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitAddNotification(context, {
        content: "Le mot de passe a bien été mis à jour",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitAddNotification(context, {
        color: "error",
        content: "Le mot de passe n'a pas pu être mis à jour",
      });
    }
  },
  async createCardRegistration(context: Context) {
    try {
      const cardRegistrationData: IMangopayCreditCardRegistration = {
        card_type: "CB_VISA_MASTERCARD",
      };
      const resCardRegistration = await api.createCardRegistration(
        context.state.keycloak.token,
        cardRegistrationData
      );
      return resCardRegistration.data;
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async postCardData(context: Context, payload: IMangopayPostCardData) {
    try {
      let postCardData: IMangopayCardData = {
        data: payload.PreregistrationData,
        accessKeyRef: payload.AccessKey,
        cardNumber: payload.cardNumber,
        cardExpirationDate: payload.cardExpirationDate,
        cardCvx: payload.cardCvx,
      };
      const resPostCardData = await api.postCardData(
        payload.CardRegistrationURL,
        postCardData
      );
      let registrationData = resPostCardData.data;
      if (registrationData.split("=")[0] != "data") {
        // TODO implement better errors using this -> https://docs.mangopay.com/guide/errors
        throw "Invalid Credit Card Data";
      } else {
        return registrationData;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async updateCardRegistration(
    context: Context,
    payload: IUpdateRegistration
  ) {
    try {
      const registrationData: IMangopayUpdateCreditCardRegistration = {
        registration_data: payload.registrationData,
      };
      let resUpdateCardRegistration = await api.updateCardRegistration(
        context.state.keycloak.token,
        payload.registrationId,
        {
          registration_data: payload.registrationData,
        }
      );
      return resUpdateCardRegistration.data;
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async createCreditCard(context: Context, payload: ICreditCardCreate) {
    try {
      let cardRegistration = await dispatchCreateCardRegistration(context);

      const expirationYearFormated = new Date(
        payload.expiration_year
      ).toLocaleString("default", { year: "2-digit" });
      const cardData: IMangopayPostCardData = {
        PreregistrationData: cardRegistration.preregistration_data,
        AccessKey: cardRegistration.access_key,
        CardRegistrationURL: cardRegistration.card_registration_url,
        cardNumber: payload.card_number,
        cardExpirationDate: payload.expiration_month + expirationYearFormated,
        cardCvx: Number(payload.cvx),
      };
      let registrationData = await dispatchPostCardData(context, cardData);

      const updateCardRegistrationData: IUpdateRegistration = {
        registrationId: cardRegistration.id,
        registrationData: registrationData,
      };
      cardRegistration = await dispatchUpdateCardRegistration(
        context,
        updateCardRegistrationData
      );
      return cardRegistration;
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async setHasValidCreditCardRegistered(context: Context, isValid: boolean) {
    api.updateMe(context.state.keycloak.token, { has_valid_credit_card_registered: isValid });
  },
  async deactivateCreditCard(context: Context, cardId: number) {
    api.deactivateCard(context.state.keycloak.token, cardId);
  },
  async createCreditCardAndPayin(
    context: Context,
    payload: ICreditCardCreateAndPayin
  ) {
    // Creating credit card
    const creditCardRegistration = await dispatchCreateCreditCard(
      context,
      payload
    );
    // Payin
    const paymentMethod = payload.register_card
      ? "REGISTERED_CARD"
      : "ADHOC_CARD";
    const rechargeWalletPayload: IRechargeWalletCreate = {
      card_id: creditCardRegistration.card_id,
      amount_in_cents: payload.amount_in_cents,
      browser_info: payload.browser_info,
      method: paymentMethod,
      origin_url: payload.origin_url,
    };
    await context.dispatch("createRechargeWallet", rechargeWalletPayload);
  },
  async getCreditCards(context: Context) {
    try {
      const response = await api.getCreditCards(context.state.keycloak.token);
      if (response.data) {
        commitSetCreditCards(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async createRechargeWallet(
    context: Context,
    payload: IRechargeWalletCreate
  ) {
    try {
      const response = await api.createRechargeWallet(
        context.state.keycloak.token,
        payload
      );

      if (response.data.secure_mode_needed) {
        commitAddNotification(context, {
          content: "Redirection pour raison de sécurité",
        });
        window.location.replace(response.data.secure_mode_redirect_url);
      } else if (response.data.status === "FAILED") {
        commitAddNotification(context, {
          content: "Votre compte n'a pas pu être crédité",
          color: "error",
        });
          router.push(response.data.redirect_path);
        } else {
          router.push(response.data.redirect_path);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getPayin(context: Context, payinId: number) {
    try {
      const response = await api.getPayin(
        context.state.keycloak.token,
        payinId
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getPassePartoutPayinByMangopayPayinId(context: Context, payinId: number) {
    try {
      const response = await api.getPassePartoutPayinByMangopayPayinId(
        context.state.keycloak.token,
        payinId
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getTab(context: Context) {
    try {
      const response = await api.getTab(context.state.keycloak.token);
      if (response.data) {
        context.state.tab = response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getWallet(context: Context) {
    try {
      const response = await api.getWallet(context.state.keycloak.token);
      if (response.data) {
        commitSetWallet(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async sendVerificationEmail(context: Context) {
    try {
      await api.sendVerificationEmail(context.state.keycloak.token);
      commitAddNotification(context, {
        content: `Email de vérification envoyé à ${context.state.keycloak.tokenParsed.email}`,
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async verifyEmail(context: Context, payload: IEmailVerificationToken) {
    try {
      const response = await api.verifyEmail(context.state.keycloak.token, payload);
      if (response.data) {
        return response.data.redirect_uri;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getAccessRights(context: Context) {
    try {
      const response = await api.getAccessRights(context.state.keycloak.token);
      if (response.data) {
        context.commit("setAccessRights", response.data);
        const contentIds = [
          ...new Set(
            response.data.map((accessRight) => accessRight.content_id)
          ),
        ];
        dispatchGetContents(context, contentIds);
        dispatchGetPublishers(context);

        const createdBefore = new Date().toISOString().replace("Z", "");
        context.dispatch("getTransactions", {
          createdBefore: createdBefore,
          limit: 10,
        });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getContents(context: Context, contentIds: number[]) {
    try {
      const response = await api.getContents(
        context.state.keycloak.token,
        contentIds
      );
      if (response.data) {
        context.commit("setContents", response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getPublishers(context: Context) {
    try {
      const response = await api.getPublishers(context.state.keycloak.token);
      if (response.data) {
        context.commit("setPublishers", response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async getPayins(context: Context, payload: IReadPayin) {
    const payins = await api.getPayins(
      context.state.keycloak.token,
      payload.createdBefore,
      payload.createdAfter,
      "SUCCEEDED",
      payload.limit
    );
    if (payins.data.length === 0) {
      return [];
    }
    return payins.data.map((payin) => {
      return {
        id: "payin-" + payin["id"],
        created_at: new Date(payin["created_at"] + "Z"),
        created_at_day_localtime: new Date(
          payin["created_at"].split("T")[0]
        ).toISOString(),
        type: "payin",
        data: {
          amount: payin["amount"],
          method: payin["method"],
        },
      };
    });
  },
  async getContentCredits(context: Context, payload: IReadPayin) {
    const contentCredits = await api.getContentCredits(
      context.state.keycloak.token,
      payload.createdBefore,
      payload.createdAfter,
      payload.limit
    );

    if (contentCredits.data.length === 0) {
      return [];
    }
    let formatedContentCredits = contentCredits.data.map((contentCredit) => {
      return {
        id: "content-credit-" + contentCredit["id"],
        created_at: new Date(contentCredit["created_at"] + "Z"),
        created_at_day_localtime: new Date(
          contentCredit["created_at"].split("T")[0]
        ).toISOString(),
        type: "content-credit",
        data: {
          origin: contentCredit["origin"],
          publisher_id: contentCredit["publisher_id"],
          start_balance: contentCredit["start_balance"],
        },
      };
    });

    // Add publisher data
    let allPublisherIds = contentCredits.data.map((contentCredit) => {
      if (contentCredit.publisher_id) {
        return contentCredit.publisher_id;
      }
    });
    allPublisherIds = [...new Set(allPublisherIds)];
    const allPublishers = (
      await api.getPublishers(context.state.keycloak.token, allPublisherIds)
    ).data;

    formatedContentCredits = formatedContentCredits.map(function (
      contentCredit
    ) {
      contentCredit.data["publisher"] = allPublishers.find(
        (publisher) => contentCredit.data.publisher_id == publisher.id
      );
      return contentCredit;
    });

    return formatedContentCredits;
  },
  async getPurchases(context: Context, payload: IReadTransactions) {
    let createdAfter = undefined;
    const resPurchases = await api.getPurchases(
      context.state.keycloak.token,
      payload.createdBefore,
      createdAfter,
      payload.limit
    );

    if (resPurchases.data.length) {
      const purchases = resPurchases.data;
      createdAfter = purchases[purchases.length - 1]["created_at"];

      const allAccessRightIds = purchases
        .map((purchase) => {
          return purchase.access_right_ids;
        })
        .flat();
      const allAccessRights = (
        await api.getAccessRights(
          context.state.keycloak.token,
          allAccessRightIds
        )
      ).data;

      // fetch allContents
      if (allAccessRights.length) {
        let allContentIds = allAccessRights.map((access_right) => {
          return access_right.content_id;
        });
        allContentIds = [...new Set(allContentIds)];
        const allContents = (
          await api.getContents(context.state.keycloak.token, allContentIds)
        ).data;
        // fetch allPublishers
        if (allAccessRights.length) {
          let allPublisherIds = allContents.map((content) => {
            return content.publisher_id;
          });
          allPublisherIds = [...new Set(allPublisherIds)];
          const allPublishers = (
            await api.getPublishers(
              context.state.keycloak.token,
              allPublisherIds
            )
          ).data;

          let formattedPurchases = purchases.map((purchase) => {
            return {
              id: "purchase-" + purchase["id"],
              invoice_id: purchase["invoice_id"],
              created_at: new Date(purchase["created_at"] + "Z"),
              created_at_day_localtime: new Date(
                purchase["created_at"].split("T")[0]
              ).toISOString(),
              type: "purchase",
              data: {
                content_ids: purchase.access_right_ids.map(function (
                  access_right_id
                ) {
                  return allAccessRights.find(
                    (all_access_right) =>
                      access_right_id === all_access_right.id
                  ).content_id;
                }),
                contents: undefined,
                publisher: undefined,
                access_right_ids: purchase["access_right_ids"],
                user_paid_amount: purchase["user_paid_amount"],
                content_credit_id: purchase["content_credit_id"],
              },
            };
          });

          // Add content data
          formattedPurchases = formattedPurchases.map(function (purchase) {
            purchase.data["contents"] = purchase.data.content_ids.map(function (
              content_id
            ) {
              return allContents.find((content) => content_id === content.id);
            });
            return purchase;
          });

          // Add publisher data
          formattedPurchases = formattedPurchases.map(function (purchase) {
            purchase.data["publisher"] = allPublishers.find(
              (publisher) =>
                purchase.data.contents[0].publisher_id == publisher.id
            );
            return purchase;
          });
          return formattedPurchases;
        }
      }
    }
    return [];
  },
  async getTransactions(context: Context, payload: IReadTransactions) {
    let transactions = [];
    try {
      const res = await api.getTransactions(
        context.state.keycloak.token,
        payload.createdBefore,
        payload.limit,
      )
      transactions = res.data
      commitAppendTransactions(context, transactions);
      return transactions;
      // const purchases = await actions.getPurchases(context, payload);
      // purchases.forEach((p) => transactions.push(p));

      // let createdAfter = new Date(0, 0, 0);
      // if (purchases.length > 0) {
      //   createdAfter = purchases[purchases.length - 1]["created_at"];
      // }
      // const payins = await actions.getPayins(context, {
      //   createdBefore: payload.createdBefore,
      //   createdAfter: createdAfter.toISOString().replace("Z", ""),
      //   limit: payload.limit,
      // });
      // payins.forEach((p) => transactions.push(p));

      // //get Content Credits
      // const contentCredits = await actions.getContentCredits(context, {
      //   createdBefore: payload.createdBefore,
      //   createdAfter: createdAfter.toISOString().replace("Z", ""),
      //   limit: payload.limit,
      // });
      // contentCredits.forEach((cc) => transactions.push(cc));

      // // Order transactions by descending order
      // transactions.sort((a, b) => b.created_at - a.created_at);

    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  },
  async refreshWallet(context: Context) {
    await api.refreshWallet(context.state.keycloak.token)
  },
  async createSlimpayOrder(context: Context, data: ISlimpayOrderCreate) {
    const response = await api.createSlimpayOrder(context.state.keycloak.token, data);
    if (response) {
      const slimpayOrder: ISlimpayOrder = response.data
      if (environment === 'production') {
        window.location.replace(slimpayOrder['aisUrl']);
      } else {
        window.open(slimpayOrder['aisUrl'], '_blank').focus();
      }
    }
  },
  async createGocardlessBillingRequest(context: Context, data: IGocardlessBillingRequestCreate) {
    const response = await api.createGocardlessBillingRequest(context.state.keycloak.token, data);
    if (response) {
      return response.data;
    }
  },
  async checkUserHasValidMandate(context: Context) {
    await api.checkUserHasValidMandate(context.state.keycloak.token);
  },
  async getTinkReport(context: Context, accountVerificationReportId: string) {
    try {
      const response = await api.getTinkReport(context.state.keycloak.token, accountVerificationReportId);
      if (response) {
        const tinkReport: ITinkReport = response.data
        return tinkReport
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as any);
    }
  }
};

const { dispatch } = getStoreAccessors<State | any, State>("");

export const dispatchCheckApiError = dispatch(actions.checkApiError);
export const dispatchGetUserProfile = dispatch(actions.getUserProfile);
export const dispatchGetUserProfileOrCreate = dispatch(
  actions.getUserProfileOrCreate
);
export const dispatchLogOut = dispatch(actions.logOut);
export const dispatchUpdateUserProfile = dispatch(actions.updateUserProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchGetCreditCards = dispatch(actions.getCreditCards);
export const dispatchCreateCreditCard = dispatch(actions.createCreditCard);
export const dispatchCreateCardRegistration = dispatch(
  actions.createCardRegistration
);
export const dispatchPostCardData = dispatch(actions.postCardData);
export const dispatchUpdateCardRegistration = dispatch(
  actions.updateCardRegistration
);
export const dispatchDeactivateCreditCard = dispatch(
  actions.deactivateCreditCard
);
export const dispatchCreateCreditCardAndPayin = dispatch(
  actions.createCreditCardAndPayin
);
export const dispatchGetContents = dispatch(actions.getContents);
export const dispatchGetPublishers = dispatch(actions.getPublishers);
export const dispatchRefreshWallet = dispatch(actions.refreshWallet);
