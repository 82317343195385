const env = process.env.VUE_APP_ENV;

let envAppUrl = "";
let envApiUrl = "";
let envKeycloakUrl = "";
let envKeycloakRealm = "";

if (env === "production") {
  envAppUrl = `https://${process.env.VUE_APP_PASSEPARTOUT_APP_URL_PROD}`;
  envApiUrl = `https://${process.env.VUE_APP_PASSEPARTOUT_API_URL_PROD}`;
  envKeycloakUrl = `https://${process.env.VUE_APP_KEYCLOAK_URL_PROD}`;
  envKeycloakRealm = process.env.VUE_APP_KEYCLOAK_REALM_PROD;
} else if (env === "staging") {
  envAppUrl = `https://${process.env.VUE_APP_PASSEPARTOUT_APP_URL_STAG}`;
  envApiUrl = `https://${process.env.VUE_APP_PASSEPARTOUT_API_URL_STAG}`;
  envKeycloakUrl = `https://${process.env.VUE_APP_KEYCLOAK_URL_STAG}`;
  envKeycloakRealm = process.env.VUE_APP_KEYCLOAK_REALM_STAG;
} else {
  envKeycloakRealm = process.env.VUE_APP_KEYCLOAK_REALM_DEV;
  envAppUrl = `http://${process.env.VUE_APP_PASSEPARTOUT_APP_URL_DEV}`;
  if (process.env.VUE_APP_PASSEPARTOUT_API_URL_DEV.includes('localhost')) {
    envApiUrl = `http://${process.env.VUE_APP_PASSEPARTOUT_API_URL_DEV}`;
  } else {
    envApiUrl = `https://${process.env.VUE_APP_PASSEPARTOUT_API_URL_DEV}`;
  }
  if (process.env.VUE_APP_KEYCLOAK_URL_DEV.includes('localhost') || process.env.VUE_APP_KEYCLOAK_URL_DEV.includes('keycloak')) {
    envKeycloakUrl = `http://${process.env.VUE_APP_KEYCLOAK_URL_DEV}`;
  } else {
    envKeycloakUrl = `https://${process.env.VUE_APP_KEYCLOAK_URL_DEV}`;
  }
}

export const environment = env;
export const apiUrl = envApiUrl;
export const appUrl = envAppUrl;
export const keycloakUrl = envKeycloakUrl;
export const keycloakRealm = envKeycloakRealm;
