
export const initCrisp = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "9049fa1c-de3a-4d87-9175-3942cbf08217";
    window.CRISP_RUNTIME_CONFIG = {
        locale: "fr"
    };
    (function () {
        const script = document.createElement("script");
        script.src = "https://client.crisp.chat/l.js";
        script.async = 1;
        document.getElementsByTagName("head")[0].appendChild(script);
    })();
}