import Vue from "vue";
import Vuex from "vuex";
import { mutations } from "@/store/mutations";
import { getters } from "@/store/getters";
import { actions } from "@/store/actions";
import { State } from "./state";

Vue.use(Vuex);

const defaultState: State = {
  environment: 'development',
  keycloak: null,
  logInError: false,
  signUpError: false,
  usernameExists: false,
  userProfile: null,
  notifications: [],
  creditCards: [],
  wallet: null,
  tab: {
    no_mandate_threshold_in_cents: null,
    tab_trigger_payment_threshold_in_cents: null,
    is_mandate_required: null,
    is_mandate_active: null,
    balance_in_cents: null,
    general_content_credit_balance: null,
  },
  accessRights: [],
  payins: [],
  purchases: [],
  transactions: [],
  contents: [],
  publishers: [],
  billingAddress: {
    street1: "",
    street2: "",
    postal_code: null,
    city: "",
    country: "FR"
  },
};

export const store = new Vuex.Store<State>({
  state: defaultState,
  mutations,
  actions,
  getters,
});

export default store;
