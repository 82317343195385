// Import Component hooks before component definitions
import "./component-hooks";
import Vue from "vue";
import "./plugins/vuetify";
import "./plugins/vee-validate";
import { initCrisp } from "./plugins/crisp";
import App from "./App.vue";
import store from "@/store";
import router from "@/router";
import "./registerServiceWorker";
import "vuetify/dist/vuetify.min.css";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import Vuetify from "vuetify";
import updateToken from "@/middlewares/update-token";
import { keycloakUrl, keycloakRealm, appUrl, environment } from "@/env";


declare global {
  interface Window {
      $crisp: any;
  }
};

store.commit("setEnvironment", environment);

Vue.config.productionTip = false;

Vue.use(VueKeyCloak, {
  init: {
    onLoad: "check-sso",
    pkceMethod: "S256",
    checkLoginIframe: false,
  },
  config: {
    realm: keycloakRealm,
    url: keycloakUrl + '/auth',
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  },
  logout: {
    redirectUri: appUrl,
  },
  onReady: async (keycloak) => {
    store.commit("setKeycloak", keycloak);
    
    if (Vue.prototype.$keycloak.authenticated) {
      await store.dispatch("getUserProfileOrCreate");
    }

    new Vue({
      vuetify: new Vuetify(),
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
    
    window.onfocus = () => {
      updateToken();
    };

    if (store.state.environment === 'production') {
      initCrisp()
      window.$crisp.push(["set", "user:nickname", [Vue.prototype.$keycloak.fullName]]);
      window.$crisp.push(["set", "user:email", [Vue.prototype.$keycloak.userName]]);
    }
  }
});
  